import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import {
  faArchive,
  faDownload,
  faPen,
  faPlus,
  faSearchMinus,
  faSearchPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useJobDetails } from "../../../providers/jobDetailsProvider";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { jobInvoicesApi } from "../../../services/jobInvoicesServices";
import JobInvoiceModal from "./invoiceStatus/JobInvoiceModal";
import ConfirmationModal from "../../ConfirmationModal";
import { utils } from "../../../utils/utils";
import CreateInvoiceModal from "./invoiceStatus/CreateInvoiceModal";
import JobInvoiceDetail from "./invoiceStatus/JobInvoiceDetail";
import QBImport from "../../../pages/admin/reports/QBImport";
import { commonApi } from "../../../services/commonServices";

const TYPE_PDF = "pdf";
const MAX_PAGE_SIZE = 999;
const APPROVED = "APPROVED";
const EXEC = "Exec";
const ACCOUNTING = "Accounting";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const InvoiceStatusTab = () => {
  const [jobDetails] = useJobDetails();
  const [loading, setLoading] = useState();
  const [invoiceDetail, setInvoiceDetail] = useState({});

  const [showJobInvoiceModal, setShowJobInvoiceModal] = useState(false);
  const [addJobInvoiceModal, setAddJobInvoiceModal] = useState(false);

  const [localInvoices, setLocalInvoices] = useState([]);
  const [refresh, setRefresh] = useState();
  const [terms, setTerms] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await jobInvoicesApi.getJobInvoices({
        jobId: jobDetails.job.id,
        pageSize: MAX_PAGE_SIZE,
      });
      const termsGotten = await commonApi.getTerms();
      setTerms(termsGotten);
      const rawData = result?.data.map((invoice) => ({
        ...invoice,
        rawAmount: `$${invoice.amount}`,
      }));
      setLocalInvoices(rawData);
      setLoading(false);
    };
    if (jobDetails.job?.id) {
      fetchData();
    }
  }, [jobDetails.job, refresh]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const onDownload = () => {
    return;
    /*
    setLoading(true);
    jobsApi
      .invoiceStatusReport({
        jobId: jobDetails.job.id,
      })
      .then((res) => {
        const data = new Blob([res], { type: "application/pdf" });
        const URL = window.URL.createObjectURL(data);
        setLoading(false);
        return window.open(URL, "new");
      })
      .catch(() => {
        setLoading(false);
      });
      */
  };

  const onQBImport = () => {
    setInformationModal({
      isOpen: true,
      title: "QB Import",
      rawBody: true,
      size: "xl",
      body: <QBImport invoices={localInvoices} />,
    });
  };

  const onEdit = (invoice) =>
    setShowJobInvoiceModal({ ...invoice, isEdit: true });

  const onToggleDetails = (invoice) =>
    setInvoiceDetail({
      ...invoiceDetail,
      [invoice.id]: !invoiceDetail[invoice.id],
    });

  const onDelete = (invoice) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setLoading(true);
        await jobInvoicesApi.deleteInvoice(invoice);
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Remove Job Invoice",
          body: "Job Invoice Removed Successfully",
          onClose: () => {
            setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            });
            setRefresh(!refresh);
          },
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Remove Job Invoice",
      body: `
            <div class="text-center">
                Do you confirm you want to remove this invoice?
            </div>
      `,
      confirmColor: "warning",
    });
  };

  const mapInvoiceType = (type) =>
    type === EXEC.toUpperCase() ? ACCOUNTING : EXEC;

  return (
    <Col className="mt-3 col-12">
      <Row className="d-flex justify-content-between px-3 align-items-center">
        <h3 className="font-weight-bold">Job Invoices</h3>
        <div className="d-flex justify-content-end">
          {localInvoices?.length ? (
            <Button className="rounded mr-2" size="sm" onClick={onQBImport}>
              <FontAwesomeIcon icon={faArchive} />
              <span className="ml-2">QB Import</span>
            </Button>
          ) : null}
          <Button
            className="rounded mr-2"
            size="sm"
            onClick={() => setAddJobInvoiceModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ml-2">Add Item</span>
          </Button>
          <Button
            color="tertiary"
            size="sm"
            className="rounded d-none"
            onClick={() => onDownload(TYPE_PDF)}
          >
            <span className="font-weight-bold">PDF</span>
            <FontAwesomeIcon icon={faDownload} className="ml-1" />
          </Button>
        </div>
      </Row>
      <Row>
        <Col className="flex-shrink-0 py-3">
          {loading ? (
            <div className="my-2">
              <Loader size="sm" />
            </div>
          ) : (
            <Table className="col-12 px-0 border rounded mb-0">
              <thead className="small">
                <tr className="text-muted bg-lighter">
                  <th width={"10%"} className="text-left">
                    Invoice#
                  </th>
                  <th width={"10%"} className="text-center">
                    Amount
                  </th>
                  <th width={"35%"} className="text-center">
                    Description
                  </th>
                  <th width={"15%"} className="text-center">
                    Status
                  </th>
                  <th width={"10%"} className="text-center">
                    Sent Date
                  </th>
                  <th width={"10%"} className="text-center">
                    Paid Date
                  </th>
                  <th width={"10%"} className="text-right">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {localInvoices.length > 0 ? (
                  <>
                    {localInvoices.map((invoice) => {
                      return (
                        <>
                          <tr key={`invoice-${invoice.id}`}>
                            <td className="text-left" width={"10%"}>
                              {invoice.invoiceNumber || "-"}
                            </td>
                            <td className="text-center" width={"10%"}>
                              {invoice.amount
                                ? utils.formatCurrency(invoice.amount)
                                : "-"}
                            </td>
                            <td className="text-center" width={"35%"}>
                              {invoice.description || "-"}
                            </td>
                            <td className="text-center" width={"15%"}>
                              {invoice.status === APPROVED
                                ? `${
                                    invoice.type
                                      ? mapInvoiceType(invoice.type)
                                      : ""
                                  } Approved`
                                : "Pending"}
                            </td>
                            <td className="text-center" width={"10%"}>
                              {invoice.sentDate
                                ? utils.formatDate(invoice.sentDate)
                                : "-"}
                            </td>
                            <td className="text-center" width={"10%"}>
                              {invoice.paidDate
                                ? utils.formatDate(invoice.paidDate)
                                : "-"}
                            </td>
                            <td className="text-right" width={"10%"}>
                              <div className="d-flex align-items-center justify-content-end">
                                <Button
                                  size="sm"
                                  className="rounded"
                                  color="primary"
                                  onClick={() => onToggleDetails(invoice)}
                                >
                                  {invoiceDetail[invoice.id] ? (
                                    <FontAwesomeIcon icon={faSearchMinus} />
                                  ) : (
                                    <FontAwesomeIcon icon={faSearchPlus} />
                                  )}
                                </Button>
                                <Button
                                  size="sm"
                                  className="rounded ml-2"
                                  color="warning"
                                  onClick={() => onEdit(invoice)}
                                >
                                  <FontAwesomeIcon icon={faPen} />
                                </Button>
                                <Button
                                  size="sm"
                                  className="rounded ml-2"
                                  color="danger"
                                  onClick={() => onDelete(invoice)}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                              </div>
                            </td>
                          </tr>
                          {invoiceDetail[invoice.id] ? (
                            <tr>
                              <td colSpan={7}>
                                <JobInvoiceDetail
                                  jobInvoice={invoice}
                                  setRefresh={setRefresh}
                                  job={jobDetails.job}
                                />
                              </td>
                            </tr>
                          ) : null}
                        </>
                      );
                    })}
                    <tr className="font-weight-bold">
                      <td className="text-left" width={"20%"}>
                        Total Invoiced
                      </td>
                      <td className="text-center" width={"10%"}>
                        {utils.formatCurrency(
                          localInvoices.reduce((p, c) => p + c.amount, 0)
                        )}
                      </td>
                      <td className="text-center" width={"10%"}></td>
                      <td className="text-center" width={"10%"}></td>
                      <td className="text-center" width={"40%"} colSpan={2}>
                        Total Remaining
                      </td>
                      <td className="text-right text-danger" width={"10%"}>
                        {utils.formatCurrency(
                          jobDetails.job.contractAmount -
                            localInvoices.reduce((p, c) => p + c.amount, 0)
                        )}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center small">
                      No items to show
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          size={informationModal.size}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : showJobInvoiceModal ? (
        <JobInvoiceModal
          defaultItem={showJobInvoiceModal}
          onClose={() => setShowJobInvoiceModal(false)}
          onSubmit={() => {
            setShowJobInvoiceModal(false);
            setRefresh(!refresh);
          }}
          terms={terms}
        />
      ) : addJobInvoiceModal ? (
        <CreateInvoiceModal
          onClose={() => setAddJobInvoiceModal(false)}
          onSubmit={() => {
            setAddJobInvoiceModal(false);
            setRefresh(!refresh);
          }}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Col>
  );
};

export default InvoiceStatusTab;
